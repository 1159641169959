import {useFetchRequest} from '~/composables/fetch/useFetchRequest'
import {compileAbsoluteRequestUrl, getAuthServerUrl} from '~/api'
import type {CSPotSession} from '~/types/user'
import {useStateCSpotSession} from '~/composables/auth/useStateCSpotSession'
import type {Ref} from 'vue'
import type {FetchRequestConfig} from '~/types/fetch'

export const useFetchAuthAuthorized = async <TResult>(config: FetchRequestConfig) => {
  const stateSession: Ref<CSPotSession> = useStateCSpotSession()
  const {access_token} = toValue(stateSession)

  const headers: FetchRequestConfig['headers'] = {}

  if (access_token) {
    headers.authorization = `Bearer ${access_token}`
  }

  return useFetchRequest<TResult>({
    ...config,
    url: compileAbsoluteRequestUrl(getAuthServerUrl(), config.url),
    ...(Object.keys(config.headers ?? {}).length !== 0 ? {headers: {...config.headers, ...headers}} : {headers}),
  });
}
