<template>
  <Modal :showModal="showModal">
    <div class="flex flex-col bg-white dark:bg-cs-dark-background rounded-xl">
      <div class="flex flex-col p-6 rounded-t-xl justify-center items-center">
        <IconClose class="cursor-pointer self-end" @click="() => toggleModal(false)" />
        <template v-if="!thereIsAWallet()">
          <CSTitle
            :latestWordsHighlighted="2"
            :text="$t('register.walletNotFound')"
            class="text-5xl w-[178px] text-center leading-[44px]"
          />
          <Separator class="my-5" />
          <p class="text-cs-black dark:text-white w-[274px] text-center">{{ $t('register.apologies') }}</p>
          <div class="flex justify-center mt-5 w-full">
            <Button :onClick="() => toggleModal(false)" :text="$t('register.back')" variant="light" />
          </div>
        </template>
        <template v-else>
          <CSTitle :text="$t('register.connectWallet')" class="text-5xl w-[540px] text-start leading-[44px]" />
          <p class="text-cs-black dark:text-white w-[540px] text-start">{{ $t('register.selectWallet') }}</p>
          <Separator class="my-5" />
          <div class="grid grid-cols-2 gap-5">
            <div
              v-for="wallet in wallets"
              class="flex justify-between items-center border rounded-[4px] py-4 px-10 ml-5 cursor-pointer w-[260px]"
              @click="() => loginWithWallet(wallet.name)"
            >
              <span class="capitalize text-cs-black dark:text-white leading-5">{{
                $t('register.walletName', {name: wallet.name})
              }}</span>
              <img :src="wallet.icon" class="w-10 h-10" />
            </div>
          </div>
        </template>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import IconClose from 'assets/icons/close.svg'
import {BrowserWallet} from '@meshsdk/core'
import {useGenerateNonce} from '~/composables/wallet'

interface ComponentProps {
  showModal: boolean
  toggleModal: Function
  method: Function
}

const props = defineProps<ComponentProps>()

const {$showErrorToast} = useNuxtApp()

let wallets = ref<InstalledWallet[]>([])

const thereIsAWallet = () => {
  return wallets?.value?.length > 0 || false
}

const loginWithWallet = async (walletName: string) => {
  try {
    const wallet = await BrowserWallet.enable(walletName)
    const userAddress = (await wallet.getRewardAddresses())[0]
    const {error, data} = await useGenerateNonce(userAddress)
    if (error && error.value) {
      $showErrorToast(error)
    } else {
      const nonce = data.value.nonce

      const signature = await wallet.signData(userAddress, nonce)

      await props.method(userAddress, signature, walletName)
    }
  } catch (e) {
    $showErrorToast(`Oops, something went wrong connecting ${walletName}!`)
  }
}

onMounted(async () => {
  wallets.value = (await BrowserWallet.getInstalledWallets()) as InstalledWallet[]
})
</script>
