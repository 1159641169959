import {loadSessionData} from '~/composables/auth/useAuth'
import {GTM_EVENT_SSO_WALLET_REGISTRATION, GTM_EVENT_TYPE_SUBMIT} from '~/constants/gtmEvent'
import {useFetchAuthBasic} from '~/composables/fetch/useFetchAuthBasic'
import {useFetchAuthAuthorized} from '~/composables/fetch/useFetchAuthAuthorized'

export const useGenerateNonce = async (userAddress: string) => {
  return useFetchAuthBasic({
    url: () => `auth/wallet/get-nonce?address=${userAddress}`,
    method: 'get',
  })
}

export const useLoginWithWallet = async (
  address: string,
  signature: WalletSignature,
  walletName: string,
  locale,
  languageCookie,
  $eventTracker
) => {
  const {error, data} = await useFetchAuthBasic({
    url: 'auth/wallet/sign-up',
    method: 'post',
    body: {address, signature, walletName}
  })

  if (error.value) {
    return {error: error.value}
  }

  try {
    // @ts-ignore TODO: add proper types to request
    const {access_token, refresh_token, isRegistration} = data.value
    await loadSessionData(access_token, refresh_token, locale, languageCookie)

    if (isRegistration) {
      $eventTracker.triggerEvent(GTM_EVENT_SSO_WALLET_REGISTRATION, GTM_EVENT_TYPE_SUBMIT)
    }
    return {error: null}
  } catch (e) {
    return {error: e}
  }
}

export const useLinkWallet = async (address: string, signature: WalletSignature, walletName: string) => {
  return useFetchAuthAuthorized({
    url: 'auth/wallet/link-wallet',
    method: 'post',
    body: {address, signature, walletName},
  })
}

export const useUnlinkWallet = async () => {
  return useFetchAuthAuthorized({
    url: 'auth/wallet/unlink-wallet',
    method: 'post'
  })
}

export const useGetUserQrCode = async () => {
  return useFetchAuthAuthorized({
    url: 'auth/wallet/generate-qr',
    method: 'get'
  })
}
